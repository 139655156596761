$font-family-sans-serif: 'Exo 2', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #333;
$body-color: #888;

$headings-color: #000;
$headings-font-weight: bold;

$primary: #E20000;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 6px;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 6px;

$nav-tabs-link-active-bg: $primary;

$navbar-light-color:                #fff;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-bg: #000;
$navbar-link-hover-bg: $primary;
$navbar-link-active-bg: $primary;

$hamburger-color: #000;

$story-headings-color: #000;

$pagination-bg: #000;
$pagination-color: #fff;

@import "../../fonts/exo-2";
@import "../../magazine";

.brand-logo img {
  height: auto;
  max-height: 80px;
}

.navbar {
  border-top: 7px solid $primary;
  background-color: $black;

  .nav-link {
    color: $white;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    @include font-size(1.125rem);

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-nav .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@include media-breakpoint-up(sm) {
  .navbar .pur-mgmt ul {
    padding-top: $navbar-padding-y;

    li:last-of-type a {
      border-bottom: 1px solid $navbar-item-separation-border-color;
    }
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.sidebar .card.filled {
  background-color: $black;
  color: $white;

  .card-header {
    color: $white;
    @include font-size(1.25rem);
  }
}

.section-caption {
  color: #888;
  @include font-size(1.25rem);
}


.navbar.navbar-expand .nav-item+.nav-item::after {
  display: none;
}


.section-head .more {
  border-color: $primary;
  @include plain-hover-focus {
    color: $primary;
  }
}

article .pagination {
  font-weight: $font-weight-bold;
}

.partner-logo {
  width: 60px;
  @include font-size(0.5rem);

  span {
    white-space: nowrap;
  }
}
